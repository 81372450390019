@import '../../../../scss/theme-bootstrap';

.content-block-split-width-product {
  @include typography-body-text;
  width: 100%;
  z-index: auto;
  &:not(.max-width) {
    max-width: none;
  }
  .content-block__inner {
    display: flex;
    flex-direction: column;
    @include breakpoint($landscape-up) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .content-block__picture--badge {
    width: 100%;
    padding-bottom: 24px;
    img {
      width: 80px;
    }
  }
  .mobile-align-#{$ldirection} {
    &.text-alignment {
      text-align: $ldirection;
    }
  }
  .position-media-product & {
    &__card-section {
      order: 3;
      @include breakpoint($landscape-up) {
        order: 2;
      }
    }
  }
  &__text-wrapper {
    &.content-block__text-wrapper {
      @include breakpoint($landscape-up) {
        width: 25%;
        height: auto;
        padding: 0 20px;
      }
    }
    .content-block__content {
      @include breakpoint($landscape-up) {
        height: 100%;
      }
    }
    .content-block__text-mobile-hidden {
      .content-block__line--content--1,
      .content-block__line--content--2,
      .content-block__line--content--3 {
        display: none;
        @include breakpoint($portrait-up) {
          display: block;
        }
      }
    }
  }
  &__card-section {
    .content-block--content-card &,
    .content-block--product-card & {
      @include breakpoint($landscape-up) {
        width: calc(25% - 20px);
        margin-#{$ldirection}: 20px; 
      }
      .content-block--with-border & {
        @include breakpoint($landscape-up) {
          border-#{$ldirection}: 1px solid $color-gray-op15;
        }
        &.default-padding-card-section {
          @include breakpoint($landscape-up) {
            padding: 40px 0;
          }
        }
        &.default-vertical-card-section {
          @include breakpoint($landscape-up) {
            margin: 0;
          }
        }
      }
      .content-block--with-border.dark-background & {
        border-color: $color-white-op15;
      }
    }
    .tout-block__media-wrapper {
      padding-top: 0;
    }
    .tout-block__wrapper {
      &.tout-block__wrapper-pad-default {
        padding-bottom: 0;
      }
    }
  }
  &__content-card {
    display: none;
    &.show-content-card-mobile {
      display: flex;
    }
    @include breakpoint($landscape-up) {
      position: relative;
      display: flex;
      visibility: visible;
      height: 100%;
    }
    &.default-padding-content-card {
      @include breakpoint($landscape-up) {
        padding: 0 20px;
      }
    }
    &.position-bottom-content {
      @include breakpoint($landscape-up) {
        align-items: flex-end;
      }
    }
    .content-block__inner-content {
      margin: 0 auto;
      width: 100%;
    }
  }
  &__product-card {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    z-index: -1;
    @include breakpoint($landscape-up) {
      position: relative;
      display: flex;
      visibility: visible;
      opacity: 1;
      z-index: auto;
      height: 100%;
    }
    .content-block__inner-product-card {
      width: 100%;
    }
    &.position-top-product {
      @include breakpoint($landscape-up) {
        align-items: flex-start;
      }
    }
    &.position-bottom-product {
      @include breakpoint($landscape-up) {
        align-items: flex-end;
      }
    }
    .product-cart-open & {
      position: fixed;
      bottom: 0;
      background: $color-white;
      z-index: 1006;
      #{$ldirection}: 0;
      width: 100%;
      height: auto;
      visibility: visible;
      opacity: 1;
      max-height: none;
      .content_block-product-card {
        .product-brief__abstract-item {
          display: inline-flex;
          @include breakpoint($landscape-up) {
            display: inline;
          }
        }
      }
      .product-brief__image-wrapper {
        ul.slick-dots {
          position: relative;
          float: $ldirection;
          margin-bottom: 14px;
          margin-top: 4px;
          @include breakpoint($landscape-up) {
            position: absolute;
            float: none;
          }
        }
      }
      a.slick-slide {
        &.product-brief__image-link {
          max-width: fit-content;
          @include breakpoint($landscape-up) {
            max-width: none;
          }
          img {
            max-width: calc(100vw - 40px);
            float: $ldirection;
            @include breakpoint($landscape-up) {
              max-width: none;
              float: none;
            }
            &.product-brief__image--alt {
              width: auto;
            }
          }
        }
      }
    }
    .content_block-product-card {
      .content-block__product-grid {
        .content-block--with-border & {
          .mpp-grid__wrapper {
            @include breakpoint($medium-up) {
              @include swap_direction(margin, 0 5px 0 10px);
            }
            @include breakpoint($xlarge-up) {
              margin: 0 20px;
            }
          }
        }
        .product-brief {
          &__abstract {
            display: block;
            text-align: $ldirection;
          }
          &__image-container {
            margin-bottom: 9px;
          }
          &__price {
            margin-bottom: 20px;
          }
          &__sized-title {
            margin-bottom: 0;
          }
        }
      }
      .product-brief {
        &__shades {
          @include breakpoint($landscape-up) {
            min-width: 210px;
          }
          @include breakpoint($large-up) {
            min-width: 280px;
          }
          .product-brief-shades__grid {
            padding: 0 25px;
            @include breakpoint($landscape-up) {
              display: block;
            }
          }
        }
        &__sized-buttons {
          min-height: auto;
          .sku-list {
            &__list {
              padding: 0;
            }
            &__item {
              .sku-list__button {
                margin: 0 10px 16px;
              }
            }
          }
        }
        &__product-info-wrapper {
          height: auto;
        }
      }
      .content-block__product-grid {
        @include breakpoint($landscape-up) {
          overflow-y: auto;
          scrollbar-color: $color-core-light-gray transparent;
          scrollbar-width: thin;
        }
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          background: $color-core-light-gray;
          border-radius: 20px;
        }
      }
      .content-block-product__close {
        display: block;
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
    }
  }
  &__product-card-close {
    position: absolute;
    #{$rdirection}: 10px;
    top: 14px;
    z-index: 1000;
    @include breakpoint($landscape-up) {
      display: none;
    }
    &--icon {
      width: 14px;
      height: 14px;
    }
  }
  &__product-card-overlay {
    display: none;
    background-color: $color-gray-op50;
    position: fixed;
    width: 100%;
    top: 0;
    height: 100%;
    #{$ldirection}: 0;
    z-index: 1005;
    .product-cart-open & {
      display: block;
    }
  }
  .editorial-product-grid:last-child .mpp-grid__item {
    margin-bottom: 0;
  }
  .carousel-dots .slick-dots {
    li button {
      display: inline-block;
      width: 12px;
      height: 1px;
      padding: 0;
      background-color: $color-gray-op65;
      &:before {
        display: none;
      }
    }
    li.slick-active button {
      height: 2px;
      margin-top: 0;
      background-color: $color-core-black;
    }
  }
  .carousel-arrows {
    display: none;
  }
  &__media-wrapper {
    @include breakpoint($landscape-up) {
      width: 75%;
    }
    .content-block--content-card &,
    .content-block--product-card & {
      @include breakpoint($landscape-up) {
        width: 50%;
      }
    }
    .content-block--with-border & {
      @include breakpoint($landscape-up) {
        border-#{$ldirection}: 1px solid $color-gray-op15;
        height: auto;
      }
      &.default-padding-media-wrap {
        @include breakpoint($landscape-up) {
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }
    }
    .content-block--with-border.dark-background & {
      border-color: $color-white-op15;
    }
  }
  &__media {
    &.default-vertical-media {
      margin: auto;
    }
    &.default-padding-media {
      @include breakpoint($landscape-up) {
        padding: 0 20px;
      }
    }
  }
  &__bellow-media {
    display: flex;
    flex-direction: column;
    .trigger-read-more {
      padding-top: 40px;
      a.show-content {
        @include typography-link-text;
        font-weight: normal;
        color: $color-gray-on-light;
        text-decoration: underline;
      }
      span.show-content-sign {
        @include typography-link-text;
        font-weight: normal;
        color: $color-gray-on-light;
      }
    }
    .content-block__picture--badge {
      padding: 24px 0 40px;
    }
  }
  .video-block[data-default-aspect-ratio='1'] {
    .videojs-video {
      aspect-ratio: 3/4;
    }
  }
  .content-block__line {
    margin: 0;
  }
  .content-block__content {
    flex-grow: 0;
  }
  .content-block__line--eyebrow {
    margin-bottom: 16px;
  }
  .content-block__line--header {
    margin: 0;
    .h1 {
      margin: 0;
    }
  }
  .content-block__line--content {
    margin: 16px 0 0;
  }
  .content-block__link--wrapper {
    margin: 16px 0 24px;
    &.content-block__link--bottom {
      margin: 40px 0 20px;
      position: absolute;
      bottom: 0;
      width: 100%;
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
      .button {
        width: 100%;
      }
    }
  }
  .content-block__line--label {
    margin: 29px 0 0;
    display: flex;
    .icon--radio {
      width: 10px;
      height: 10px;
      margin-top: 5px;
      margin-#{$rdirection}: 9px;
    }
    &.bullet-point-color {
      &-black {
        fill: $color-black;
      }
      &-core-black {
        fill: $color-core-black;
      }
      &-gray-light {
        fill: $color-gray-on-light;
      }
      &-white {
        fill: $color-white;
      }
    }
  }
  p {
    margin-bottom: 0;
    line-height: inherit;
  }
  .mantle-custom-text {
    text-align: unset;
  }
  .headline-1,
  .headline-2 {
    line-height: 0.85;
  }
  .headline-6,
  .headline-5,
  .headline-4,
  .headline-3 {
    line-height: 1;
    letter-spacing: 0;
  }
  .body-1,
  .body-2 {
    line-height: 1.1;
    margin: 0;
  }
  &__media-link {
    padding: 30px 0;
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
    .button {
      background: transparent;
      color: inherit;
      width: 100%;
    }
  }
  .link {
    @include typography-link-text;
    text-transform: none;
  }
  .cta-secondary {
    background: transparent;
  }
  .padding--default {
    padding: 30px 0 60px;
    @include breakpoint($landscape-up) {
      padding: 40px 0 0;
    }
    div:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

html.split-width-product-cart-open {
  &,
  body {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .content-formatter {
    &.product-cart-open {
      position: unset;
    }
  }
}
